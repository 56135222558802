import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";
// import Cookies from "./components/Cookies";
import ReactPixel from "react-facebook-pixel";

// Replace with your actual Pixel ID
const pixelId = "3927607780862228";

ReactPixel.init(pixelId);
ReactPixel.pageView();

ReactDOM.render(
  <Provider store={Store}>
    {/* <Cookies /> */}
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
