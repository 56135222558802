import React, { useEffect, useState } from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSidebar";
import { DataGrid } from "@material-ui/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../redux/actions/order";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { AiOutlineArrowRight } from "react-icons/ai";

const AdminDashboardOrders = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, [dispatch]);

  const columns = [
    { field: "itemNo", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) =>
        params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor",
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/admin-orders/${params.id}`}>
          <Button>
            <AiOutlineArrowRight size={20} />
          </Button>
        </Link>
      ),
    },
  ];

  const rows = adminOrders
    ? adminOrders.map((item) => ({
        itemNo: item.orderNo,
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: "Ksh" + item?.totalPrice,
        status: item?.status,
        Details: item?.createdAt.slice(0, 10),
      }))
    : [];

  // Filter rows based on the search query
  const filteredRows = searchQuery
    ? rows.filter((row) =>
        row.itemNo.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : rows;

  return (
    <div className="relative w-full">
      <Meta title="Admin Dashboard Orders" />
      <AdminHeader />
      <div className="flex">
        {/* Sidebar */}
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={2} />
        </div>

        {/* Main Content */}
        <div className="flex-1 relative">
          {/* Search Box */}
          <div className="absolute top-4 right-4 z-10">
            <input
              type="text"
              placeholder="Search Orders..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border border-gray-300 rounded px-4 py-2 w-[250px] shadow-md"
            />
          </div>

          {/* DataGrid */}
          <div className="w-full min-h-[45vh] pt-5 rounded flex justify-center mt-12">
            <div className="w-[97%] flex justify-center">
              <DataGrid
                rows={filteredRows} // Use the filtered rows
                columns={columns}
                pageSize={10}
                disableSelectionOnClick
                autoHeight
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardOrders;
