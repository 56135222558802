import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import AllProducts from "../../components/Shop/AllProducts";
import Meta from "../../components/Meta";
import { useState } from "react";

const ShopAllProducts = () => {
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  return (
    <div>
      <Meta title="Shop's all Products" />

      <DashboardHeader />

      <div className="relative flex justify-between w-full">
        {" "}
        {/* Add relative here */}
        <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={3} />
        </div>
        {/* Search Box */}
        <div className="absolute top-4 right-4 p-2">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 w-[250px]"
          />
        </div>
        <div className="w-full justify-center flex mt-12">
          <AllProducts searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  );
};

export default ShopAllProducts;
