import React, { useState } from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSidebar";
import AllProducts from "../components/Admin/AllProducts";
import Meta from "../components/Meta";

const AdminDashboardProducts = () => {
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  return (
    <div>
      <Meta title="Admin Dashboard Products" />

      {/* Admin Header */}
      <AdminHeader />

      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          {/* Sidebar */}
          <div className="w-[80px] 800px:w-[330px]">
            <AdminSideBar active={5} />
          </div>

          {/* Main Content */}
          <div className="flex-1">
            {/* Search Area */}
            <div className="flex justify-end items-center p-4">
              <input
                type="text"
                placeholder="Search products..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-gray-300 rounded px-4 py-2 w-1/3"
              />
            </div>

            {/* All Products Component */}
            <AllProducts searchQuery={searchQuery} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardProducts;
