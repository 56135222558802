import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../components/Login/Login.jsx";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.user);

  // Function to handle access token
  const handleAccessToken = async () => {
    try {
      const code = new URLSearchParams(location.search).get("code");
      const response = await axios.post(
        `http://localhost:5000/tiktok/list?code=${code}`
      );
      const data = response.data;
      console.log("Response Data:", data);
    } catch (error) {
      console.error("Error posting to /list endpoint:", error);
    }
  };

  // New function to handle photo publishing
  const handlePublishPhotos = async () => {
    try {
      const code = new URLSearchParams(location.search).get("code");
      const response = await axios.post(
        `http://localhost:5000/tiktok/tiktok/photos-publish?code=${code}`,
        {
          // Add any additional data here if required by your endpoint
        }
      );
      const data = response.data;
      console.log("Photos Publish Response Data:", data);
    } catch (error) {
      console.error("Error posting to /photos-publish endpoint:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  return (
    <div>
      {code ? (
        <div>
          <button onClick={handleAccessToken}>Get TikTok Access Token</button>
          <button onClick={handlePublishPhotos}>Publish Photos</button>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default LoginPage;
